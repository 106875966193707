import { useRoutes } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { ThemeProviderWrapper } from 'src/theme/ThemeProvider';
import router from 'src/routing/router';
import { LocalizationProvider } from '@mui/x-date-pickers';

export const App = () => {
  const content = useRoutes(router);

  return (
    <ThemeProviderWrapper>
      <LocalizationProvider>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProviderWrapper>
  );
};
