import { Navigate, RouteObject } from 'react-router';
// import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';
// import { SuspenseLoader } from 'src/components/SuspenseLoader/SuspenseLoader';
// import { lazy, Suspense } from 'react';
import { LoginScreen } from 'src/screens/login/LoginScreen';

// const Loader = (Component: any) => (props: any) =>
//   (
//     <Suspense fallback={<SuspenseLoader />}>
//       <Component {...props} />
//     </Suspense>
//   );

// const SamplePage = Loader(lazy(() => import('src/screens/sample/SamplePage')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <LoginScreen />,
    children: [
      // {
      //   path: '/',
      //   element: <Overview />
      // },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="404" replace />
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />
      //     },
      //     {
      //       path: 'coming-soon',
      //       element: <StatusComingSoon />
      //     }
      //   ]
      // },
      // {
      //   path: '*',
      //   element: <Status404 />
      // }
    ]
  },
  // {
  //   path: 'dashboards',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to='crypto' replace />
  //     },
  //     {
  //       path: 'crypto',
  //       element: <SamplePage />
  //     }
  //   ]
  // }
  {
    path: '*',
    element: <Navigate to='/' replace />
  }
];

export default routes;