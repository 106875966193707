import './loginScreenStyled.scss';
import React from 'react';
import { LogoSign } from 'src/components/LogoSign/LogoSign';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

export const LoginScreen = () => {
  return (
      <div className="loginScreen">
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <div className="login100-pic">
                <LogoSign/>
              </div>

              <form className="login100-form validate-form">
              <span className="login100-form-title">
                eMed Healthcare Platform
              </span>

                <div className="wrap-input100">
                  <input className="input100" type="text" placeholder="Email"/>
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <EmailIcon/>
                  </span>
                </div>

                <div className="wrap-input100">
                  <input className="input100" type="password" placeholder="Password"/>
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <LockIcon/>
                  </span>
                </div>

                <div className="container-login100-form-btn">
                  <button
                      style={{cursor: 'not-allowed'}}
                      onClick={(e) => e.preventDefault()}
                      className="login100-form-btn"
                  >
                    Login
                  </button>
                </div>

                <div className="text-center p-t-12">
                  <span className="txt2" style={{cursor: 'not-allowed', textDecoration: 'underline'}}>
                    Forgot your password?
                  </span>
                </div>

                <div className="text-center p-t-15">
                  <span className="txt2" style={{cursor: 'not-allowed', textDecoration: 'underline'}}>
                    Create your account
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  );
};